import {
  Button,
  Col,
  Divider,
  Dropdown,
  Flex,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import {
  createRepresent,
  createSession,
  deleteRepresent,
  getRepresents,
  sendAudio,
  updateContent,
  updateSession,
} from "../../services/apiService";
import "../../styles.css";
import { MdDeleteOutline } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
// import user1 from "./assets/images/user1.png";
// import user2 from "./assets/images/user2.png";
// import user3 from "./assets/images/user3.png";
import { useTranslation } from "react-i18next";
import { useReactMediaRecorder } from "react-media-recorder";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { createContent, getSessionById } from "../../services/apiService";
import { MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { Menu } from "antd";

// Declare the SpeechRecognition and related objects outside the component.
const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const SpeechGrammarList =
  window.SpeechGrammarList || window.webkitSpeechGrammarList;
const SpeechRecognitionEvent =
  window.SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent;
if (!("webkitSpeechRecognition" in window))
  alert("Trình duyệt không hỗ trợ Web Speech API");
const { Paragraph } = Typography;
const mic = new SpeechRecognition();

function BocBang({
  processTranscripts,
  scrollToBottom,
  setSessions,
  listRealtimeTranscript,
  setListRealtimeTranscript,
  setCurrentSession,
  getSessionsToView,
  isListening,
  setListTranscript,
  ListTranscript,
  currentSession,
  getContentToView,
}) {
  const { t, i18n } = useTranslation("global");
  mic.continuous = true;
  mic.interimResults = true;
  mic.lang = t("languageVoice");
  // console.log('language voice::::::::',mic.lang)
  // const { isRecording, setIsRecording } = useControlContext();
  const [note, setNote] = useState(null);
  const [note2, setNote2] = useState(null);

  const [speechend, setSpeechEnd] = useState(false);
  const [represent, setRepresent] = useState(1);
  const inputRef = useRef(null);
  const [listRepresents, setListRepresents] = useState([]);
  const [selectedRepresent, setSelectedRepresent] = useState(1);
  const [presentLoading, setPresentLoading] = useState(false);
  const [openModalEditRepresentative, setOpenModalEditRepresentative] =
    useState(false);
  const [nameRepresentToEdit, setNameRepresentToEdit] = useState("");
  const [openDropDownRepresent, setOpenDropDownRepresent] = useState(false);
  const [formRep] = Form.useForm();
  const [openModalDeleteRepresent, setOpenModalDeleteRepresent] =
    useState(false);
  const [RepresentFilterArray, setRepresentFilterArray] = useState([]);
  const {
    clearBlobUrl,
    status,
    startRecording,
    stopRecording,
    pauseRecording,
    mediaBlobUrl,
  } = useReactMediaRecorder({
    audio: true,
  });
  const saveAudioAndInsertRealtimeScript = async (note, mediaBlobUrl) => {
    !mediaBlobUrl && toast.error("error audio file");
    try {
      const audioBlob = await (await fetch(mediaBlobUrl)).blob();
      const uniqID = await handleSaveNote(note);
      const res = await sendAudio(audioBlob, note, uniqID);
      if (res?.isOk && res?.object?.textAI) {
        const updatedRecord = {
          textAI: res?.object?.textAI,
          representName: res?.object?.person,
        };
        const resUpdateContent = await updateContent(
          res?.object?.id,
          updatedRecord
        );
        !!resUpdateContent &&
          setListTranscript((prev) =>
            processTranscripts(
              prev.map((record) =>
                record?.id === res?.object?.id
                  ? { ...record, ...updatedRecord }
                  : record
              )
            )
          );
        // getContentToView(currentSession?.content);
      } else if (res?.isOk === false) {
        const updatedRecord = {
          textAI: "Không nhận diện được",
          representName: "Không nhận diện được",
        };
        const resUpdateContent = await updateContent(
          res?.object?.id,
          updatedRecord
        );
        !!resUpdateContent &&
          setListTranscript((prev) =>
            processTranscripts(
              prev.map((record) =>
                record?.id === res?.object?.id
                  ? { ...record, ...updatedRecord }
                  : record
              )
            )
          );
        //   // getContentToView(currentSession?.content);
        //   console.log("send audio success");
        // Download the audio file
        // const downloadLink = document.createElement("a");
        // downloadLink.href = mediaBlobUrl;
        // downloadLink.download = "recorded_audio.wav";
        // downloadLink.click();
      }
    } catch (error) {
      console.error("Error in saving audio and inserting script:", error);
    } finally {
      scrollToBottom();
    }
  };
  const requestQueue = [];
  let isProcessing = false;

  const processQueue = async () => {
    if (isProcessing || requestQueue.length === 0) return;

    isProcessing = true;
    const { newNote, blobUrl, resolve, reject } = requestQueue.shift();

    try {
      await saveAudioAndInsertRealtimeScript(newNote, blobUrl);
      resolve();
    } catch (error) {
      reject(error);
    } finally {
      isProcessing = false;
      processQueue(); // Process the next item in the queue
    }
  };

  const addToQueue = (newNote, blobUrl) => {
    return new Promise((resolve, reject) => {
      requestQueue.push({ newNote, blobUrl, resolve, reject });
      processQueue();
    });
  };

  // useEffect(() => {
  //   if (mediaBlobUrl) {
  //     console.log("mediaBlobUrl", mediaBlobUrl);
  //   }
  // }, [mediaBlobUrl]);
  // useEffect(() => {
  //   if (recordingBlob) {
  //     saveAudioAndInsertRealtimeScript(); // Call saveAudioAndInsertRealtimeScript only when the blob is available
  //   }
  // }, [recordingBlob]);
  useEffect(() => {
    handleListen();
  }, [isListening]);
  useEffect(() => {
    handleDetectEnd();
  }, [note]);
  // useEffect(() => {
  //   if (!!isListening) {
  //     startRecording();
  //   } else {
  //     stopRecording();
  //     !!recordingBlob && saveAudioAndInsertRealtimeScript();
  //   }
  // }, [isListening]);
  // useEffect(() => {
  //   if (RepresentFilterArray?.length > 0) {
  //     setListTranscript((pre) => {
  //       return pre.filter((item) => {
  //         return !!item.id;
  //       });
  //     });
  //   } else {
  //     getContentToView(currentSession?.content);
  //   }
  // }, [RepresentFilterArray]);
  const handleDetectEnd = () => {
    // const newNote = note;
    if (!!note && /[?.]/.test(note.slice(-1))) {
      const sentences = note.split(/[?.]/).filter(Boolean); // Remove empty strings
      const lastSentence = sentences[sentences.length - 1]; // Get the last meaningful sentence
      // console.log("lastSentence", lastSentence);
      if (!note || !!["Phẩy.", "Phẩy"].includes(lastSentence.trim())) {
        return;
      }
      if (lastSentence) {
        stopRecording();
        setTimeout(() => {
          addToQueue(lastSentence, mediaBlobUrl);
        });
        startRecording();
      }
    }
  };


  const handleDeleteRepresent = async (representId) => {
    // console.log("representId", representId);
    try {
      const res = await deleteRepresent(representId);
      if (!!res) {
        getRepresentsToView();
      } else {
        toast.error("Create represent failed");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenModalDeleteRepresent(false);
    }
  };
  const getRepresentsToView = async () => {
    try {
      setPresentLoading(true);
      const res = await getRepresents();
      if (!!res) {
        res && res.length > 0 && setListRepresents(res);
      } else {
        setListRepresents([]);
      }
    } catch (error) {
      setPresentLoading(false);
      console.log(error);
    } finally {
      setPresentLoading(false);
    }
  };
  useEffect(() => {
    getRepresentsToView();
  }, []);
  // useEffect(() => {
  //   if (speechend === true) {
  //     handleSaveNote();
  //   }
  // }, [speechend]);

  const handleListen = () => {
    if (isListening) {
      if (true) {
        try {
          mic.start();
          startRecording();

          mic.onend = () => {
            if (isListening) {
              console.log("Restarting mic...");
              mic.start(); // Restart only if `isListening` is true
            } else {
              console.log("Mic stopped completely.");

            }
          };
        } catch (error) {
          console.error("Error starting SpeechRecognition:", error);
        }
      }
    } else {
      if (isListening === false) {
        try {
          mic.stop();

          stopRecording();


          mic.onend = () => {
            console.log("Mic stopped completely.");
          };
        } catch (error) {
          console.error("Error stopping SpeechRecognition:", error);
        } finally {
          // isMicRunning = false;
          setNote("");
        }
      }
    }

    mic.onstart = () => {
      console.log("Mic is running");
    };

    mic.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0].transcript)
        .join("");
      setNote(transcript);
    };

    mic.onerror = (event) => {
      console.error("Mic error:", event.error);
      mic.stop(); // Stop the mic if an error occurs
    };

    mic.onspeechend = () => {
      console.log("Speech ended.");
    };

    mic.onnomatch = () => {
      console.log("Speech not recognized.");
    };
  };

  const handleSaveNote = async (newNote) => {
    console.log('check new note', newNote);
    try {
      if (newNote === "Phẩy") {
        return;
      }
      let note = newNote;
      const uniqID = uuidv4();
      if (!note) {
        // toast.error("Note less than 1 word");
        return;
      }
      if (note.split(" ").length > 0) {
        const resContent = await createContent({
          id: uniqID,
          time: dayjs().format("YYYY-MM-DD,HH:mm:ss"),
          passage: note,
          representAvatar: "https://i.pravatar.cc/150?img=1",
        });
        if (!!resContent) {
          const session = await getSessionById(currentSession?.id);
          // not choose session
          if (!session?.id) {
            const resCreateSession = await createSession({
              id: uuidv4(),
              date: dayjs().format("YYYY-MM-DD"),
              name: "Phiên họp mới",
              content: [resContent?.id],
            });
            if (!!resCreateSession?.id) {
              setCurrentSession(resCreateSession);
              getSessionsToView();
              // setSessions((pre) => [...pre, resCreateSession]);
              getContentToView([resContent?.id]);
            } else {
              toast.error("Create session failed");
            }
          }
          // choose session
          else {
            const resUpdateSession = await updateSession(session.id, {
              ...session,
              content: [...session.content, resContent?.id],
            });
            // await getSessionsToView();
            resUpdateSession && setListTranscript((pre) => [...pre, resContent]);
            scrollToBottom();
          }
        }
      } else {
        // toast.error("Note less than 1 word");
      }
      return uniqID;
    } catch (error) {
      console.log("errorhandleSaveNote", error);
    } finally {
      // setNote("");
    }
  };
  // const repRef = useRef();

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (repRef?.current && !repRef?.current?.contains(event.target)) {
  //       setOpenDropDownRepresent(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);
  return (
    <>
      <div
        onClick={(e) => setOpenDropDownRepresent(false)}
      >
        <div className="">
          {/* <video src={mediaBlobUrl} controls loop /> */}
          {/* <Input className="" onChange={(e) => setNote(e.target.value)} />   */}
          {/* <audio src={mediaBlobUrl} controls /> */}
          <div>
            <div className="note-output">
              {note &&
                (() => {
                  const segments = note
                    ?.split(/(?<=[.?])/g) // Split at every "." or "?" and retain the delimiter
                    ?.map((segment) => segment.trim()) // Trim whitespace from each segment
                    .filter(Boolean); // Remove any empty strings
                  // Get the last meaningful sentence
                  // const lastSentence = segments[segments.length - 1];
                  if (segments.length > 2) {
                    // 
                    const TwolastSentence = segments.slice(-2).join(".");
                    return TwolastSentence ? (
                      <p key="last-note" className="note-paragraph">
                        {TwolastSentence}
                      </p>
                    ) : null; // Only render if there is a valid last sentence
                  } else if (segments.length <= 2) {
                    return (
                      <p key="last-note" className="note-paragraph">
                        {note}
                      </p>
                    ); // Only render if there is a valid last sentence
                  }
                })()}
            </div>
          </div>
        </div>
      </div>
      <Modal
        style={{ top: 60 }}
        title="Xác nhận xóa người phát ngôn"
        visible={openModalDeleteRepresent}
        onOk={() => handleDeleteRepresent(selectedRepresent)}
        onCancel={() => setOpenModalDeleteRepresent(false)}
        okText={t("confirm")}
        cancelText={t("Reject")}
      ></Modal>
    </>
  );
}

export default BocBang;
