import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "../src/index.scss";
import Layout from "../src/pages/PublicRouters/layout.jsx";
import GlobalTheme from "./theme/global.theme";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GlobalTheme>
      <Layout></Layout>
    </GlobalTheme>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
