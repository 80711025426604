import { createAppSlice } from "../../createAppSlice";

const initialState = [
  {
    id: "1",
    title: "Learn React",
    completed: false,
  },
  {
    id: "2",
    title: "Learn Redux",
    completed: false,
  },
  {
    id: "3",
    title: "Learn Redux Toolkit",
    completed: false,
  },
  {
    id: "4",
    title: "Learn React Router",
    completed: false,
  },
];

// If you are not using async thunks you can use the standalone `createSlice`.
export const todoSlice = createAppSlice({
  name: "todo",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: (create) => ({
    addTodo: create.reducer((state, action) => {
      state.unshift(action.payload); // mutate state directly
    }),
    deleteTodo: create.reducer((state, action) => {
      const id = action.payload;
      const index = state.findIndex((todo) => todo.id === id);
      state.splice(index, 1);
    }),
    updateTodo: create.reducer((state, action) => {
      const id = action.payload;
      const index = state.findIndex((todo) => todo.id === id);
      state[index].completed = !state[index].completed;
    }),
    updateTitle: create.reducer((state, action) => {
      const newTitle = action.payload.title;
      const id = action.payload.id;
      const index = state.findIndex((todo) => todo.id === id);
      state[index].title = newTitle;
    }),
    sortTodos: create.reducer((state, action) => {
      if (action.payload === "titleLength") {
        state.sort((a, b) => a.title.length - b.title.length);
      }
    }),
    // getAllTodos: create.asyncThunk(
    //   async () => {
    //     // const response = await getTodos();
    //     // The value we return becomes the `fulfilled` action payload
    //     // console.log(response);
    //     // return await response;
    //   },
    //   {
    //     pending: (state) => {},
    //     fulfilled: (state, action) => {
    //       // Return new state from payload
    //       return action.payload;
    //     },
    //     rejected: (state) => {
    //       throw new Error("Failed to fetch todos");
    //     },
    //   }
    // ),
    getInitialTodos: create.reducer((state, action) => {
      return state;
    })
  }),
  // You can define your selectors here. These selectors receive the slice
  // state as their first argument.
  selectors: {
    selectAllTodos: (state) => {
      return state;
    },
  },
});

// Action creators are generated for each case reducer function.
export const {
  addTodo,
  deleteTodo,
  updateTodo,
  getInitialTodos,
  updateTitle,
  sortTodos,
} = todoSlice.actions;

// Selectors returned by `slice.selectors` take the root state as their first argument.
export const { selectAllTodos } = todoSlice.selectors;
