import React from "react";
import App from "../../App";
import "../../index.scss";
// import { Provider } from "react-redux";
import i18next from "i18next";
import { Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import global_en from "../../locales/en/global.json";
import global_vi from "../../locales/vi/global.json";
import GlobalTheme from "../../theme/global.theme";
import { StoreProvider } from "../../StoreProvider";
i18next.init({
  interpolation: { escapeValue: false },
  lng: "auto",
  fallbackLng: "vi",
  resources: {
    en: { global: global_en },
    vi: { global: global_vi },
  },
});
const Layout = (props) => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      errorElement: <>Error</>,
      children: [
        {
          element: <></>,
          errorElement: <>Error</>,
          index: true,
        },
      ],
    },
  ]);
  return (
    <>
      <Suspense fallback="loading">
        {/* <ControlContextProvider> */}
        <I18nextProvider i18n={i18next}>
          <GlobalTheme>
            <StoreProvider>
              <RouterProvider router={router}></RouterProvider>
            </ StoreProvider>
          </GlobalTheme>
        </I18nextProvider>
        {/* </ControlContextProvider> */}
        <ToastContainer />
      </Suspense>
    </>
  );
};
export default Layout;
