import { ArrowUpOutlined, CalendarOutlined, ClockCircleOutlined, DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Form, Input, List, Popover, Row, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import BocBang from "../Controller/Bocbang";
import { deleteContent, updateContent } from "../../services/apiService";
import { getTailwindBgColorForSpeaker } from "../../functions";
import { BackTop } from 'antd';
import './ConversationCard.scss'
const { Text } = Typography;

const MainTextDisplay = ({
  processTranscripts,
  setSessions,
  listRealtimeTranscript,
  setListRealtimeTranscript,
  isContentLoading,
  setCurrentSession,
  getSessionsToView,
  currentSession,
  isListening,
  listTranscript,
  setListTranscript,
  getContentToView,
}) => {
  const [editMess, setEditMess] = useState(false);
  const [form] = Form.useForm();
  const [displayedText, setDisplayedText] = useState([]);
  const scrollContainerRef = useRef(null); // Reference to the scrollable container
  const scrollToTopRef = useRef(null);
  const handleDeleteRecord = async (recordID) => {
    try {
      const res = await deleteContent(recordID);
      if (!!res) {
        // getContentToView(currentSession?.content);
        setListTranscript((prev) =>
          prev.filter((item) => item.id !== recordID)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const scrollToBottom = () => {
    if (scrollContainerRef.current && !isUserScrolling) {
      scrollContainerRef.current.scrollTo({
        top: scrollContainerRef.current.scrollHeight,
        behavior: "smooth", // Enables smooth scrolling
      });
    }
  };
  const scrollToTop = () => {
    if (scrollContainerRef.current && !isUserScrolling) {
      scrollContainerRef.current.scrollTo({
        top: 0,
        behavior: "smooth", // Enables smooth scrolling
      });
    }
  };
  //check if scroll postion on top
  const checkScrollPosition = () => {
    if (scrollContainerRef.current) {
      const scrollTop = scrollContainerRef.current.scrollTop;
      const scrollLeft = scrollContainerRef.current.scrollLeft;
      if (scrollTop === 0) {
        scrollToTopRef.current.style.visibility = "hidden";
      }
      if (scrollTop > 0) {
        scrollToTopRef.current.style.visibility = "visible";
      }
    }
  };
  useEffect(() => {
    const scrollableElement = scrollContainerRef.current;
    if (scrollableElement) {
      // Add scroll event listener
      scrollableElement.addEventListener('scroll', checkScrollPosition);
    }
    return () => {
      if (scrollableElement) {
        // Remove event listener on cleanup
        scrollableElement.removeEventListener('scroll', checkScrollPosition);
      }
    };
  }, [scrollContainerRef?.current?.scrollTop]);
  useEffect(() => {
    if (listTranscript) {
      setDisplayedText(listTranscript);
      scrollToBottom();
    }
  }, [listTranscript]);

  const { t, i18n } = useTranslation("global");

  return (
    <div className="scroll">
      <Col
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
        }}
        bordered={false}
      >
        <div className="w-full  max-h-9 overflow-hidden">
          <BocBang
            processTranscripts={processTranscripts}
            scrollToBottom={scrollToBottom}
            setSessions={setSessions}
            listRealtimeTranscript={listRealtimeTranscript}
            setListRealtimeTranscript={setListRealtimeTranscript}
            setCurrentSession={setCurrentSession}
            getSessionsToView={getSessionsToView}
            getContentToView={getContentToView}
            currentSession={currentSession}
            isListening={isListening}
            setListTranscript={setListTranscript}
            listTranscript={listTranscript}
          />

        </div>

        {/* Header of Record List */}
        <Flex gap='small' vertical className="pb-4">
          <Text strong='true' className="text-2xl">{currentSession?.name}</Text>
          <Flex gap='small' align="center">
            <Text type='secondary' className="text-xs"> <CalendarOutlined /> {currentSession?.date}</Text>
            <Text type='secondary' className="text-xs"> • 09 : 30 AM</Text>
            <Text type='secondary' className="text-xs "> <ClockCircleOutlined /> 12:00</Text>
          </Flex>
        </Flex>

        {/* Container of recorded messages list */}
        <div  className="relative message-list-container p-[40px_0_40px_48px] rounded-lg shadow-[0_0_16px_0_rgba(186,198,211,1)] inset  pr-3 z-0">
          {listTranscript && !!listTranscript?.length && !isContentLoading && (
            <Flex 
            gap='large' vertical className="message-list" ref={scrollContainerRef}>
              {listTranscript.map((record, index) => (
                <div
                  key={index}
                  className={` ${record?.textAI ? `${getTailwindBgColorForSpeaker(record?.representName)} p-1 rounded-lg` : ''}`}
                >
                  <Flex gap="large" className="message-card" align="start">
                    <Flex vertical className='speaker-infor'>
                      <Text className='speaker-name'>{record.representName}</Text>
                      <Text type="secondary" className='time-stamp'>{record.time}</Text>
                    </Flex>

                    <div className="w-full">
                      {record?.id === editMess ? (
                        <div>
                          <Form
                            className="border border-[#83858C] rounded-lg h-full shadow-[inset_0px_0px_8px_0px_rgba(64,150,255,0.5)] p-4 me-12"
                            form={form}
                            initialValues={{
                              [`InputEdit${record?.id}`]: record?.passage,
                            }}
                          >
                            <Form.Item noStyle name={`InputEdit${record?.id}`}>
                              <Input.TextArea
                                placeholder="Nhập"
                                className="custom-input resize-none"
                                autoSize={{ minRows: 1, maxRows: 10 }}
                              />
                            </Form.Item>
                            <Flex gap="small" justify="end">
                              <div className="mess-button-container bg-gradient-to-t from-[rgba(34,90,151,1)] to-[rgba(252,252,252,1)] p-[1px] rounded-lg gap-2">
                                <Button
                                  className="mess-button flex items-center gap-2 px-4 py-2 rounded-lg border-none"
                                  onClick={() => {
                                    setEditMess(false);
                                  }}
                                >
                                  Hủy
                                </Button>
                              </div>
                              <div className="mess-button-container bg-gradient-to-t from-[rgba(34,90,151,1)] to-[rgba(252,252,252,1)] p-[1px] rounded-lg gap-2">
                                <Button
                                  className="mess-button flex items-center gap-2 px-4 py-2 rounded-lg border-none"
                                  onClick={async () => {
                                    const value = form.getFieldValue(`InputEdit${record?.id}`);
                                    await updateContent(record?.id, { passage: value });
                                    getContentToView([...currentSession?.content]);
                                    setEditMess(false);
                                  }}
                                >
                                  Lưu
                                </Button>
                              </div>
                            </Flex>
                          </Form>

                        </div>
                      ) : (
                        <Text className={!record?.textAI ? 'reveal' : 'reveal'}>
                          {record?.passage
                            .split(/(?<=[.?])/g) // Split at every "." or "?" and retain the delimiter
                            .map((segment, index) => (
                              <span key={`note${index}`}>
                                {segment.trim()}
                              </span>
                            ))}
                        </Text>
                      )}
                    </div>
                    {!editMess && (
                      <Text type="secondary">
                        <Popover
                          placement="rightTop"
                          content={
                            <div style={{
                              cursor: "pointer",
                            }}>
                              <div
                                style={{
                                  background: 'linear-gradient(to top, rgba(34, 90, 151, 1), rgba(252, 252, 252, 1))',
                                }}
                                className='p-[1px] rounded-lg'
                              >
                                <Button
                                  icon={<EditOutlined style={{ fontSize: '24px' }} />}
                                  onClick={() => {
                                    setEditMess(record?.id);
                                  }}
                                  className="mess-button flex items-center gap-2 px-4 py-2 rounded-lg border-none font-bold text-sm"
                                >
                                  Sửa
                                </Button>
                              </div>
                              <div
                                style={{
                                  background: 'linear-gradient(to top, rgba(34, 90, 151, 1), rgba(252, 252, 252, 1))',
                                }}
                                className='p-[1px] rounded-lg'
                              >
                                <Button
                                  icon={<DeleteOutlined style={{ fontSize: '24px' }} />}
                                  onClick={() => {
                                    handleDeleteRecord(record?.id);
                                  }}
                                  className="mess-button flex items-center gap-2 px-4 py-2 rounded-lg border-none text-red-500 font-bold"
                                >
                                  <span className='text-sm'>Xóa</span>

                                </Button>
                              </div>
                            </div >
                          }
                          trigger="click"
                        >
                          <MoreOutlined
                            style={{
                              fontSize: "16px",
                              color: 'rgba(9, 13, 26, 1)',
                              width: '32px',
                              height: '32px',
                              cursor: 'pointer',
                            }}
                          />
                        </Popover>
                      </Text>)}
                  </Flex>
                </div>

              ))}
              <Flex gap="large" className="message-card" align="start">
                <Flex vertical className='speaker-infor'>
                  <Text className='speaker-name'>Jphn</Text>
                  <Text type="secondary" className='time-stamp'>10:00 AM</Text>
                </Flex>
                <BocBang
                  processTranscripts={processTranscripts}
                  scrollToBottom={scrollToBottom}
                  setSessions={setSessions}
                  listRealtimeTranscript={listRealtimeTranscript}
                  setListRealtimeTranscript={setListRealtimeTranscript}
                  setCurrentSession={setCurrentSession}
                  getSessionsToView={getSessionsToView}
                  getContentToView={getContentToView}
                  currentSession={currentSession}
                  isListening={isListening}
                  setListTranscript={setListTranscript}
                  listTranscript={listTranscript}
                />
              </Flex>
            </Flex>


          )}
          {/* {isContentLoading && <Skeleton active paragraph={{ rows: 10 }} />} */}

        </div>

      </Col>
      <Row >
        <div
          ref={scrollToTopRef}
          className="absolute  z-10 "
          style={{
            top: "65%",
            left: "1%",
          }}
        >
          {/* <Button
            className="w-12 h-12"
            type="primary"
            onClick={() => {
              scrollToTop();
            }}
            prefix={<ArrowUpOutlined />}
          /> */}
          <button class="button"
            onClick={() => {
              scrollToTop();
            }}
          >
            <svg class="svgIcon" viewBox="0 0 384 512">
              <path
                d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
              ></path>
            </svg>
          </button>

        </div>
      </Row>
    </div>
  );
};

export default MainTextDisplay;
