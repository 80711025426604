import axios from "../utils/axiosCustomize";
import axiosAI from "../utils/axiosAICustomize";
import { v4 as uuidv4 } from "uuid";
// API endpoints
const USERS_ENDPOINT = "/users";
const SESSIONS_ENDPOINT = "/session";
const CONTENT_ENDPOINT = "/content";
// User APIs
export const getUsers = async () => {
  return axios.get(USERS_ENDPOINT);
};

export const getUserById = async (id) => {
  return axios.get(`${USERS_ENDPOINT}/${id}`);
};

export const createUser = async (data) => {
  return axios.post(USERS_ENDPOINT, data);
};

export const updateUser = async (id, data) => {
  return axios.put(`${USERS_ENDPOINT}/${id}`, data);
};

export const deleteUser = async (id) => {
  return axios.delete(`${USERS_ENDPOINT}/${id}`);
};

// Session APIs
export const getSessions = async () => {
  return axios.get(SESSIONS_ENDPOINT);
};

export const getSessionById = async (id) => {
  return axios.get(`${SESSIONS_ENDPOINT}/${id}`);
};

export const createSession = async (data) => {
  return axios.post(SESSIONS_ENDPOINT, data);
};

export const updateSession = async (id, data) => {
  return axios.put(`${SESSIONS_ENDPOINT}/${id}`, data);
};
export const updateSessionName = async (id, name) => {
  return axios.patch(`${SESSIONS_ENDPOINT}/${id}`, { name });
};
export const patchUpdateSession = async (id, data) => {
  return axios.patch(`${SESSIONS_ENDPOINT}/${id}`, data);
};
export const deleteSession = async (id) => {
  return axios.delete(`${SESSIONS_ENDPOINT}/${id}`);
};

// Content APIs
export const getContents = async () => {
  return axios.get(CONTENT_ENDPOINT);
};

export const getContentById = async (id) => {
  return axios.get(`${CONTENT_ENDPOINT}/${id}`);
};

export const createContent = async (data) => {
  return axios.post(CONTENT_ENDPOINT, data);
};

export const updateContent = async (id, data) => {
  return axios.patch(`${CONTENT_ENDPOINT}/${id}`, data);
};

export const deleteContent = async (id) => {
  return axios.delete(`${CONTENT_ENDPOINT}/${id}`);
};

const REPRESENT_ENDPOINT = "/represent";

// Represent APIs
export const getRepresents = async () => {
  return axios.get(REPRESENT_ENDPOINT);
};

export const getRepresentById = async (id) => {
  return axios.get(`${REPRESENT_ENDPOINT}/${id}`);
};

export const createRepresent = async (data) => {
  return axios.post(REPRESENT_ENDPOINT, data);
};

export const updateRepresent = async (id, data) => {
  return axios.put(`${REPRESENT_ENDPOINT}/${id}`, data);
};

export const deleteRepresent = async (id) => {
  return axios.delete(`${REPRESENT_ENDPOINT}/${id}`);
};

export const sendAudio = async (audioBlobFile,transcript, id) => {
  const formData = new FormData();
  formData.append("file", audioBlobFile);
  formData.append("Text", transcript);
  formData.append("ID", id);
  return axiosAI.post("/api/FileUpload/FileAudio", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

