import dayjs from "dayjs";

export function checkDateStatus(dateStr) {
  /**
   * Checks if a given date string in the format YYYY-MM-DD is yesterday, today, or neither.
   *
   * @param {string} dateStr - The date string to check.
   * @returns {string} "yesterday" if the date is yesterday,
   *                  "today" if the date is today,
   *                  "neither" otherwise.
   */
  try {
    // Parse the input date string
    const inputDate = dayjs(dateStr, "YYYY-MM-DD", true);

    // Validate date format
    if (!inputDate.isValid()) {
      throw new Error("Invalid date format. Please use YYYY-MM-DD.");
    }

    // Get today's date and yesterday's date
    const today = dayjs();
    const yesterday = today.subtract(1, "day");

    // Determine if the date is yesterday or today
    if (inputDate.isSame(today, "day")) {
      return "Hôm nay";
    } else if (inputDate.isSame(yesterday, "day")) {
      return "Hôm qua";
    } else {
      return "";
    }
  } catch (error) {
    throw new Error(error.message);
  }
}
const tailwindColors = [
  "bg-red-100",
  "bg-blue-100",
  "bg-green-100",
  "bg-yellow-100",
  "bg-purple-100",
  "bg-pink-100",
  "bg-indigo-100",
  "bg-teal-100",
  "bg-orange-100",
  "bg-gray-100",
];

const speakerColorMap = {};

export const getTailwindBgColorForSpeaker = (speakerName) => {
  if (!speakerName) return "bg-gray-300"; // Default for undefined names

  // Check if the speaker already has a color assigned
  if (speakerName in speakerColorMap) {
    return speakerColorMap[speakerName];
  }

  // Hash the speaker name to determine a unique index
  const hash = [...speakerName].reduce(
    (acc, char) => acc + char.charCodeAt(0),
    0
  );
  const colorIndex = hash % tailwindColors.length;

  // Ensure uniqueness by checking if the color is already assigned
  let assignedColor = tailwindColors[colorIndex];
  while (Object.values(speakerColorMap).includes(assignedColor)) {
    // Increment index and wrap around if necessary
    colorIndex = (colorIndex + 1) % tailwindColors.length;
    assignedColor = tailwindColors[colorIndex];
  }

  // Assign the color to the speaker
  speakerColorMap[speakerName] = assignedColor;

  return assignedColor;
};
