import {
  DownOutlined,
  FolderOutlined,
  MoreOutlined,
  SettingOutlined,
  SearchOutlined,
  UnorderedListOutlined,
  UserOutlined,
  BellFilled,
} from "@ant-design/icons";
import "animate.css";
import {
  Affix,
  Button,
  Col,
  Dropdown,
  Flex,
  Input,
  Layout,
  Menu,
  Modal,
  Row,
  Select,
  Space,
  theme,
  Avatar,
  Badge,
  Typography,
  Divider
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { FaRegEdit } from "react-icons/fa";
import { FaRegPenToSquare } from "react-icons/fa6";
import { v4 as uuidv4 } from "uuid";
import "./App.css";
import mic2 from "./assets/mic2.png";
import MainTextDisplay from "./components/TranScript/ConversationCard.jsx";
import {
  createSession,
  deleteSession,
  getContentById,
  getSessionById,
  getSessions,
  updateSessionName,
} from "./services/apiService.js";
import HistoryIcon from "../src/assets/HistoryIcon.png";
import { Form, Skeleton } from "antd";
import { MdDeleteOutline } from "react-icons/md";
import { toast } from "react-toastify";
import LogoIcon from "../src/assets/LogoIcon.png";
import CreateImg from "../src/assets/CreateImg.png"
import UploadImg from "../src/assets/UploadImg.png"
import GroupIcon from "../src/assets/GroupIcon.png";
import { checkDateStatus } from "./functions/index.js";
import WhiteTextTheme from "./theme/white.text.theme.jsx";
import { useAppDispatch } from "./lib/hooks.js";
import {
  getInitialTodos,
  selectAllTodos,
} from "./lib/features/todo/todoSlice.js";
import { useAppSelector } from "./lib/hooks.js";
import Mic from '../src/assets/micWhite.png';
const { Header, Content, Sider } = Layout;

const App = () => {
  // test redux
  const dispatch = useAppDispatch();
  //test redux
  const data = useAppSelector(selectAllTodos);
  const { t, i18n } = useTranslation("global");
  const languageOptions = [
    {
      label: (
        <span className="lang-icons">
          <span style={{ marginRight: "12px" }}>{t("language.vi")}</span>
          <ReactCountryFlag countryCode="VN" svg />
        </span>
      ),
      value: "vi",
    },
    {
      label: (
        <span className="lang-icons">
          {t("language.en")}
          <ReactCountryFlag countryCode="US" svg />
        </span>
      ),
      value: "en",
    },
  ];

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [isListening, setIsListening] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false); // New state for search input visibility
  const [listTranscript, setListTranscript] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [currentSession, setCurrentSession] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [sessionToDelete, setSessionToDelete] = useState(null);
  const [openModalCreateSession, setOpenModalCreateSession] = useState(false);
  const [openModalSaveFile, setOpenModalSaveFile] = useState(false);
  const [nameSessionToEdit, setNameSessionToEdit] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [characterCount, setCharacterCount] = useState(0);
  const [isSessionLoading, setIsSessionLoading] = useState(false);
  const [isContentLoading, setIsContentLoading] = useState(false);
  const [openModalUpdateSession, setOpenModalUpdateSession] = useState(false);
  const [listRealtimeTranscript, setListRealtimeTranscript] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRecordModalOpen, setIsRecordModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const [createSessionForm] = Form.useForm();

  const createMenuGroups = (groups) =>
    !isSessionLoading ? (
      groups?.map((group, index) => (
        <Menu.ItemGroup key={`groupmenu${index}`} title={group.title}>
          {group?.items?.map((item, idx) => (
            <Menu.Item
              style={{
                marginTop: "10px",
              }}
              key={item.id} // Use the session ID as the unique key
              onClick={async () => {
                const session = await getSessionById(item?.id);
                if (session?.id !== currentSession?.id) {
                  setCurrentSession(session);
                  getContentToView(session?.content);
                }
              }}
            >
              <div>
                <Flex justify="space-between">
                  <span>{item.name}</span>
                  {currentSession?.id === item?.id && (
                    <span>
                      <Dropdown
                        overlay={
                          <Menu
                          // onClick={({ key }) => {
                          //   if (key === "2") {
                          //     showDeleteModal(item?.id);
                          //   }
                          //   if (key === "1") {
                          //     setOpenModalUpdateSession(true);
                          //   }
                          // }}
                          >
                            <div className="custom-menu-item">
                              <Menu.Item
                                key="1"
                                onClick={() => {
                                  setOpenModalUpdateSession(true);
                                }}
                              >
                                <Space>
                                  <FaRegEdit color="blue" />
                                  <span className="text-black">
                                    {t("editSession")}
                                  </span>
                                </Space>
                              </Menu.Item>
                            </div>
                            <div className="custom-menu-item">
                              <Menu.Item
                                key="2"
                                onClick={() => showDeleteModal(item?.id)}
                              >
                                <Space>
                                  <MdDeleteOutline color="red" />
                                  <span className="text-black">
                                    {t("deleteSession")}
                                  </span>
                                </Space>
                              </Menu.Item>
                            </div>
                          </Menu>
                        }
                        trigger={["hover"]}
                      >
                        <MoreOutlined
                          style={{
                            cursor: "pointer",
                            fontSize: "16px",
                            transform: "rotate(90deg)",
                          }}
                        />
                      </Dropdown>
                    </span>
                  )}
                </Flex>
              </div>
            </Menu.Item>
          ))}
        </Menu.ItemGroup>
      ))
    ) : (
      <>
        <Skeleton active paragraph={{ rows: 10 }} i18nIsDynamicList />
      </>
    );
  // group by representName

  const processTranscripts = (transcripts) => {
    const combinedTranscripts = [];
    let previous = null;

    for (const current of transcripts) {
      // Skip transcripts without a valid representName
      if (!current.representName || current.representName.trim() === "") {
        combinedTranscripts.push({ ...current });
        continue; // Skip to the next item since we can't merge these
      }
      if (previous && current.representName === previous.representName) {
        // Combine passage of the current transcript to the previous one
        previous.passage += `.${current.passage}`;
      } else {
        // Push the previous transcript into the result array
        if (previous) {
          combinedTranscripts.push(previous);
        }
        // Update the previous reference
        previous = { ...current }; // Clone to avoid mutating the original object
      }
    }

    // Push the last item if it exists
    if (previous) {
      combinedTranscripts.push(previous);
    }

    return combinedTranscripts.sort((a, b) =>
      dayjs(a.time, "YYYY-MM-DD,HH:mm:ss").isBefore(
        dayjs(b.time, "YYYY-MM-DD,HH:mm:ss")
      )
        ? -1
        : 1
    );
  };

  // useEffect(() => {
  //   if (listTranscript) {
  //     // Use a memoized function to process the listTranscript
  //     const combinedTranscripts = processTranscripts(listTranscript);
  //     setListTranscript(combinedTranscripts);
  //   }
  // }, [listTranscript]);
  const showDeleteModal = (sessionId) => {
    setSessionToDelete(sessionId);
    setDeleteModalVisible(true);
  };
  const handleDeleteConfirm = async () => {
    if (sessionToDelete) {
      await handleDeleteSession(sessionToDelete);
      setDeleteModalVisible(false);
      setSessionToDelete(null);
    }
  };
  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setSessionToDelete(null);
  };
  const getSessionsToView = async () => {
    try {
      setIsSessionLoading(true);
      const data = await getSessions();
      // Group by date
      const groupedByDate = data.reduce((acc, session) => {
        const { date } = session;
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(session);
        return acc;
      }, {});
      const groupedArray = Object.entries(groupedByDate)
        .map(([date, sessions]) => ({
          title: date,
          items: sessions,
        }))
        .sort((a, b) => {
          const dateA = new Date(a.title);
          const dateB = new Date(b.title);
          return dateB - dateA;
        })
        .map(({ title, items }) => ({
          title: checkDateStatus(title) !== "" ? checkDateStatus(title) : title,
          items: items,
        }));
      //sort groupedArray by title

      setSessions(groupedArray);
      setIsSessionLoading(false);
    } catch (error) {
      setIsSessionLoading(false);
      toast.error("Get sessions failed");
      console.log(error);
    } finally {
      setIsSessionLoading(false);
    }
  };

  const handleCreateSession = async () => {
    try {
      const name = createSessionForm?.getFieldValue("nameSession");
      if (!name) {
        toast.error("Vui lòng nhập tên phiên họp");
        setOpenModalCreateSession(false);
        return;
      }
      const res = await createSession({
        id: uuidv4(),
        date: dayjs().format("YYYY-MM-DD"),
        name: name,
        content: [],
      });
      if (!!res) {
        setCurrentSession(res);
        getSessionsToView();
        getContentToView([]);
        handleCancelRecordModal();
        handleCancel();
      } else {
        toast.error("Create session failed");
      }
    } catch (error) {
      console.log(error);
      handleCancelRecordModal();
    }
  };
  const handleEditSessionName = async (id, name) => {
    try {
      const nameToEdit = name;
      if (!nameToEdit) {
        toast.error("Vui lòng nhập tên phiên họp");
        return;
      }
      const res = await updateSessionName(id, nameToEdit);
      if (!!res) {
        toast.success("Update session name successfully");
        getSessionsToView();
      } else {
        toast.error("Update session name failed");
      }
      setOpenModalUpdateSession(false);
      setNameSessionToEdit("");
    } catch (error) {
      setOpenModalUpdateSession(false);
      console.log(error);
    } finally {
      setOpenModalUpdateSession(false);
    }
  };

  const handleDeleteSession = async (id) => {
    const res = await deleteSession(id);
    if (!!res) {
      toast.warning("Delete session successfully");
      getSessionsToView();
    }
  };
  const getContentToView = async (ids) => {
    try {
      setIsContentLoading(true);
      if (!ids || !ids.length) {
        setListTranscript([]);
        return;
      }
      const data = await Promise.all(ids.map((id) => getContentById(id)));
      const wordCount = data.reduce((total, item) => {
        const words = item?.passage?.split(" ");
        return total + words?.length;
      }, 0);
      const characterCount = data.reduce((total, item) => {
        const characters = item?.passage || "";
        return total + characters.length;
      }, 0);
      console.log("check word count", wordCount);
      setWordCount(Number(wordCount));
      setCharacterCount(Number(characterCount));
      setListTranscript(
        !!data?.filter((item) => !!item?.passage)
          ? processTranscripts(data?.filter((item) => !!item?.passage))
          : []
      );
      setIsContentLoading(false);
    } catch (error) {
      toast.error("Error fetching content:", error);
      setListTranscript([]);
    } finally {
      setIsContentLoading(false);
    }
  };

  const handleListening = () => {
    setIsListening(!isListening);
  };

  // Modal handlers

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Handlers for ghi âm trực tiếp modal
  const showRecordModal = () => {
    setIsModalOpen(false); // Hide the first modal
    setIsRecordModalOpen(true); // Show the second modal
  };

  const handleCancelRecordModal = () => {
    setIsRecordModalOpen(false); // Hide the second modal
    setIsModalOpen(true); // Reopen the first modal
  };

  const handleOkRecordModal = () => {
    setIsRecordModalOpen(false); // Hide the second modal
  };
  // Handlers for upload modal
  const showUploadModal = () => {
    setIsModalOpen(false); // Hide the first modal
    setIsUploadModalOpen(true); // Show the second modal
  };

  const handleCancelUploadModal = () => {
    setIsUploadModalOpen(false); // Hide the second modal
    setIsModalOpen(true); // Reopen the first modal
  };

  const handleOkUploadModal = () => {
    setIsUploadModalOpen(false); // Hide the second modal
  };

  const toggleSearch = () => {
    setIsSearchVisible((prev) => !prev); // Toggle visibility
  };
  useEffect(() => {
    if (collapsed) {
      setIsSearchVisible(false);
    }
  }, [collapsed]);
  useEffect(() => {
    getSessionsToView();
    // getContentToView([1]);
  }, []);
  useEffect(() => {
    if (currentSession) {
      getContentToView(currentSession?.content);
    }
  }, [currentSession]);

  const userMenu = (
    <Menu
      items={[
        {
          label: "Thông tin tài khoản",
          key: "profile",
        },
        {
          label: "Đăng xuất",
          key: "logout",
          style: { color: "red" },
        },
      ]}
    />
  );

  return (
    <div className="">
      <Layout>
        <Header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "#ffffff",
            maxHeight: "80px",
            paddingTop: "4px",
          }}
        >
          <div
            className="demo-logo ml-4"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              display: "flex",
              zIndex: 1,
              alignItems: "center",
            }}
          >
            <img src={LogoIcon} alt="Logo Boc Bang" />
            <div className="ml-3 text-3xl font-bold"> {t("webname")} </div>
          </div>

          {/* Avatar and Notifications */}
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <img src={HistoryIcon} alt="history" style={{ width: "40px" }} />
            {/* Notification Icon */}
            <Badge count={1}>
              <BellFilled style={{ fontSize: "30px", color: "#000" }} />
            </Badge>

            {/* User Info with Dropdown */}
            <Dropdown overlay={userMenu} placement="bottomRight" arrow>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Avatar size="large" icon={<UserOutlined />} />
                <div style={{ marginLeft: "8px" }}>
                  <span>Xin chào,</span>
                  <span style={{ fontWeight: "bold" }}>
                    CT - HDND TP Hà Nội
                  </span>
                </div>
                <DownOutlined />
              </div>
            </Dropdown>
          </div>
        </Header>
        <Layout className="mt-2">
          <div className="sider">
            {/* <WhiteTextTheme> */}
            <Sider
              trigger={null}
              collapsible
              collapsed={collapsed}
              onCollapse={(value) => setCollapsed(value)}
              width={330}
              style={{
                backgroundColor: "#ffffff",
                paddingInline: "10px",
                borderRadius: "10px",
              }}
            >
              <Menu
                className="custom-menu"
                mode="vertical "
                style={{

                  backgroundColor: "#ffffff",
                  overflow: "auto",
                  height: "calc(100vh - 64px)",
                  display: collapsed ? "flex" : "block",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                selectedKeys={[sessions?.id]}
              >
                <Flex
                  className="mt-4 mb-4 "
                  align="center"
                  justify="space-between"
                >
                  <div
                    className="custom-trigger"
                    onClick={() => setCollapsed(!collapsed)}
                    style={{
                      display: "flex",                              
                      alignItems: "center",
                      padding: "12px",
                      width: "100px",
                    }}
                  >
                    <img
                      src={GroupIcon}
                      alt="Custom Trigger"
                      style={{
                        width: "30px",
                        height: "30px",
                        marginRight: !collapsed ? "10px" : "0",
                        cursor: "pointer",
                        marginLeft: collapsed ? "2px" : "0",
                        position: collapsed ? "fixed" : "static",
                        top: collapsed ? "90px" : "0",
                      }}
                    />
                  </div>
                  {!collapsed && (
                    <Menu.Item
                      style={{
                        backgroundColor: "#158AC4",
                        width: "295px",
                      }}
                      onClick={() => {
                        setOpenModalCreateSession(true);
                      }}
                    >
                      <Flex
                        align="center"
                        justify="space-between"
                        onClick={() => setIsModalOpen(true)}
                      >
                        <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Tạo cuộc họp mới
                        </span>
                        <FaRegPenToSquare fontSize={25} />
                      </Flex>
                    </Menu.Item>
                  )}
                </Flex>

                {!collapsed && (
                  <Menu >
                    {createMenuGroups(sessions)}
                  </Menu>
                )}
                {/* {createMenuGroups(sessions)} */}
                {/* Bottom Section (Settings Icon) */}
                <div
                  style={{
                    padding: "12px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {collapsed ? (
                    <SettingOutlined
                      style={{
                        fontSize: "30px",
                        position: "fixed",
                        bottom: "10px",
                      }}
                    />
                  ) : (
                    <Flex
                      align="center"
                      style={{
                        width: "100%",
                        justifyContent: "space-between",
                        cursor: "pointer",
                      }}
                    >
                      <span style={{ fontSize: "16px" }}>Cài đặt</span>
                      <SettingOutlined style={{ fontSize: "24px" }} />
                    </Flex>
                  )}
                </div>
              </Menu>
            </Sider>



            {/* </WhiteTextTheme> */}
          </div>
          <Layout
            style={{
              paddingLeft: "10px",
              borderRadius: "10px",
            }}
          >
            <Content style={{
              margin: "0 18px 18px 18px", padding: 24,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              minHeight: '850px',
            }}
            >
              {!currentSession && (
                <div className="w-full h-full flex justify-center items-center">
                  <div>
                    <div className="text-3xl font-bold">
                      Chọn phien hợp để bắt đầu
                    </div>
                    <div className="text-xl">
                      Tạo hoặc chọn phien hợp để bắt đầu ghi chú
                    </div>
                  </div>
                </div>
              )}
              {/* <div>{JSON.stringify(data)}</div> */}
              {!!currentSession && (
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <MainTextDisplay
                    processTranscripts={processTranscripts}
                    setSessions={setSessions}
                    listRealtimeTranscript={listRealtimeTranscript}
                    setListRealtimeTranscript={setListRealtimeTranscript}
                    wordCount={wordCount}
                    isContentLoading={isContentLoading}
                    setCurrentSession={setCurrentSession}
                    getSessionsToView={getSessionsToView}
                    getContentToView={getContentToView}
                    currentSession={currentSession}
                    isListening={isListening}
                    listTranscript={listTranscript}
                    setListTranscript={setListTranscript}
                  />

                  {/* 'Recording or not' buttons */}
                  <div className="absolute  -bottom-12 right-1/2 translate-x-1/2 p-4" >
                    {!isListening ? (
                      <div className="p-4 micro" onClick={() => handleListening()}>
                        <img src={Mic} alt="mic" width={40} height={40} />
                      </div>
                    ) : (
                      <Flex
                        className="relative right-6 min-w-52 bottom-8"
                        vertical={false}
                        onClick={() => {
                          handleListening();
                        }}
                      >
                        <Space>
                          <div className="flex items-center gap-4 absolute translate-y-1/2 bottom-0 right-1/2 translate-x-1/2">
                            <div className="p-4 micro z-50" >
                              <img src={Mic} alt="resume button" width={50} height={50} />
                            </div>

                            <Flex className="recording" gap="large" align="center">
                              <div className="pause-square" ></div>
                              <Flex gap="small">
                                <span className="square-top"></span>
                                <div className="time-recording">00:00:00</div>
                              </Flex>
                            </Flex>
                          </div>
                        </Space>
                      </Flex>
                    )}
                  </div>

                </div>
              )}

              {/* <Affix className="max-h-9 " style={{}} offsetBottom={0}>
                <Layout.Footer
                  className=" px-4"
                  style={{
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <Col span={24}>
                    <Row>
                      <Flex className="relative bottom-5" align="flex-start">
                        <Flex>
                          <Space
                            style={{
                              color: "#635E5E",
                              fontFamily: "Inter",
                            }}
                            className="text-sm font-normal"
                            size={20}
                          >
                            <Typography>Số từ: {wordCount} </Typography>
                            <Typography>Số ký tự: {characterCount}</Typography>
                            <Typography>Số người tham gia: 4</Typography>
                          </Space>
                        </Flex>
                        <Flex className="ml-12 relative bottom-1">
                          <Space size={20}>
                            <Button type="default">Tóm tắt văn bản</Button>
                            <Button type="default">Sao chép văn bản</Button>
                            <Dropdown
                              className="max-w"
                              overlay={
                                <Menu
                                  onClick={({ key }) => {
                                    setOpenModalSaveFile(key);
                                  }}
                                  items={[
                                    { key: "1", label: "Lưu dưới dạng PDF" },
                                    { key: "2", label: "Lưu dưới dạng Word" },
                                    { key: "3", label: "Lưu dưới dạng TXT" },
                                  ]}
                                />
                              }
                              placement="topCenter"
                              trigger={["click"]}
                            >
                              <Button type="default">Lưu văn bản</Button>
                            </Dropdown>
                          </Space>
                        </Flex>
                      </Flex>
                    </Row>
                  </Col>
                </Layout.Footer>
              </Affix> */}
            </Content>
          </Layout>
        </Layout>
        {/* Modal for delete confirmation */}
        <Modal
          title="Xác nhận xóa phiên"
          visible={isDeleteModalVisible}
          onOk={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          okText="Yes, Delete"
          cancelText="Cancel"
        >
          <p className="text-gray-300">Bạn có chắc muốn xóa phiên?</p>
        </Modal>

        <Modal
          bodyStyle={{ height: "269px" }}
          title="Lưu tài liệu này"
          visible={openModalSaveFile}
          // onOk={() => handleCreateSession(nameSessionToCreate)}
          onCancel={() => setOpenModalSaveFile(false)}
          okText={t("confirm")}
          cancelText={t("Reject")}
        >
          <Space className="w-full" direction="vertical">
            <Form Layout="vertical">
              <div className="flex gap-10 flex-col ">
                <Form.Item layout="vertical" label="Tên tài liệu">
                  <Input
                    size="large"
                    placeholder="File Name"
                  // value={nameSessionToCreate}
                  // onChange={(e) => setNameSessionToCreate(e.target.value)}
                  />
                </Form.Item>
                <Form.Item layout="vertical" label="Chọn địa chỉ lưu">
                  <Select
                    suffixIcon={<DownOutlined />}
                    size="large"
                    className="w-full"
                    prefix={<FolderOutlined />}
                  >
                    <Select.Option value="option1">Option 1</Select.Option>
                    <Select.Option value="option2">Option 2</Select.Option>
                    <Select.Option value="option2">Option 2</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </Form>
          </Space>
        </Modal>
        <Modal
          title="Đổi tên phiên họp"
          visible={openModalUpdateSession}
          onOk={() =>
            handleEditSessionName(currentSession?.id, nameSessionToEdit)
          }
          onCancel={() => setOpenModalUpdateSession(false)}
          okText={t("confirm")}
          cancelText={t("Reject")}
        >
          <Input
            placeholder="Enter session name to update"
            value={nameSessionToEdit}
            onChange={(e) => setNameSessionToEdit(e.target.value)}
          />
        </Modal>
        {/* Modal for "Tạo cuộc họp mới" */}
        <Modal
          title={
            <h2 style={{ textAlign: "center", color: "#204771" }}>
              Bản bóc băng mới
            </h2>
          }
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={500}
          centered
          footer={null}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Button
              style={{ width: "400px" }}
              type="default"
              onClick={showRecordModal}
            >
              Ghi âm trực tiếp
            </Button>
            <Button
              style={{ width: "400px" }}
              type="default"
              onClick={showUploadModal}
            >
              Ghi âm có sẵn
            </Button>
          </div>
        </Modal>

        {/*  Modal ghi âm trực tiếp*/}
        <Modal
          title={
            <h2 style={{ textAlign: "center", color: "#204771" }}>
              Bản bóc băng mới
            </h2>
          }
          open={isRecordModalOpen}
          onCancel={handleCancelRecordModal}
          centered
          footer={null}
        >
          <h1 className="font-bold mb-4">Ghi âm trực tiếp</h1>
          <p>Ghi âm trực tiếp sử dụng microphone của máy, </p>
          <p className="mb-4">Thời gian ghi âm tối đa: 05 giờ</p>
          <Form form={createSessionForm} className="mb-3">
            <Form.Item name="nameSession" rules={[{ required: true }]}>
              <Input placeholder="Nhập tên bản bóc băng mới" />
            </Form.Item>
          </Form>
          <Button
            className="mb-5"
            style={{ width: "100%" }}
            type="default"
            onClick={handleCreateSession}
          >
            Tiếp tục
          </Button>
          <img src={CreateImg} alt="create" width={500} />
        </Modal>

        {/* Modal upload ghi âm */}
        <Modal
          title={
            <h2 style={{ textAlign: "center", color: "#204771" }}>
              Bản bóc băng mới
            </h2>
          }
          open={isUploadModalOpen}
          onCancel={handleCancelUploadModal}
          centered
          footer={null}
        >
          <h1 className="font-bold mb-4">Từ ghi âm có sẵn</h1>
          <p>Hỗ trợ bóc băng từ link video YouTube, Facebook, Vimeo,...</p>
          <p className="mb-4">
            Thời gian xử lý từ 3 đến 5 phút tùy độ dài video
          </p>

          <Form className="mb-3">
            <Form.Item name="note" rules={[{ required: true }]}>
              <Input placeholder="Dán link hội thoại ghi âm sẵn vào đây" />
            </Form.Item>
          </Form>
          <Button
            style={{ width: "100%" }}
            type="default"
            onClick={handleOkUploadModal}
          >
            Tiếp tục
          </Button>
          <Divider
            style={{
              borderColor: "#83858C",
            }}
          >
            Hoặc
          </Divider>
          <img src={UploadImg} alt="upload" width={500} />
        </Modal>
      </Layout>
    </div>
  );
};

export default App;
